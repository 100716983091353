import React from "react";
import { useQuery } from "@tanstack/react-query";
import { SettingsUsersTable } from "./SettingsUsersTable";
import * as api from "../../../api";
import { PulseLoader } from "react-spinners";
import { isAllBusiness, mergeUserEmails } from "../../../api";
import { useBusinessContext } from "../../../hooks/useBusinessContext";
import { UserRowModel } from "./SettingsUsersRow";
import { UsersSettingsTab, UsersSettingsTabs } from "./tabs";
import { useAccessToken } from "../../../Auth/useAccessToken";
import { SearchBox } from "../../../components/SearchBox";
import { useSelf } from "../../../hooks/useSelf";
import * as demo from "./demo";

export function SettingsUsers() {
  const businessContext = useBusinessContext();
  const { accessToken } = useAccessToken();
  const self = useSelf();
  const userIsDemo = self.data?.is_demo ?? false;

  const [selectedTab, setSelectedTab] = React.useState<UsersSettingsTab>(UsersSettingsTabs.active);
  const [searchTerm, setSearchTerm] = React.useState("");

  const usersQuery = useQuery({
    queryKey: ["users", accessToken, businessContext.business?.id, userIsDemo],
    queryFn: async () => {
      if (userIsDemo) {
        return demo.getDemoUsers();
      }
      return api.getUsers(accessToken, businessContext.business!.id);
    },
    enabled: Boolean(businessContext.business),
  });

  const inactiveFoodicsAccountsQuery = useQuery({
    queryKey: ["accounts", accessToken, businessContext.business?.id, userIsDemo],
    queryFn: async () => {
      if (userIsDemo) {
        return demo.getDemoInactiveFoodicsAccounts();
      }
      return api.getInactiveFoodicsAccounts(accessToken, businessContext.business!.id);
    },
    enabled:
      selectedTab === UsersSettingsTabs.inactive &&
      businessContext.business &&
      !isAllBusiness(businessContext.business.id),
  });

  const isLoading = usersQuery.isLoading || inactiveFoodicsAccountsQuery.isLoading;

  const users = React.useMemo<UserRowModel[]>(() => {
    if (!usersQuery.isSuccess) return [];
    let users = usersQuery.data;
    if (selectedTab === UsersSettingsTabs.active) {
      users = usersQuery.data.filter((user) => user.is_active);
    } else if (selectedTab === UsersSettingsTabs.pending) {
      users = usersQuery.data.filter((user) => !user.is_active);
    }

    if (searchTerm !== "") {
      users = users.filter((u) =>
        mergeUserEmails(u).toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return users.map((u) => {
      return {
        id: u.id,
        name: u.name,
        email: mergeUserEmails(u),
        branchesCount: u.venues_count,
        businessesCount: u.businesses_count,
        is_root: u.is_root,
      };
    });
  }, [usersQuery.isSuccess, usersQuery.data, selectedTab, searchTerm]);

  const inactiveFoodicsAccounts = React.useMemo<UserRowModel[]>(() => {
    if (!inactiveFoodicsAccountsQuery.isSuccess) return [];

    const acc = inactiveFoodicsAccountsQuery.data.filter((a) => {
      return a.email.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const mappedAccounts = acc.map((a) => {
      return {
        id: a.id,
        name: a.name,
        email: a.email,
        branchesCount: a.branches.length,
        roles: a.roles.map((r) => r.name),
      };
    });

    return mappedAccounts.sort((a, b) => {
      const countA = a.branchesCount || 0;
      const countB = b.branchesCount || 0;
      return countB - countA;
    });
  }, [inactiveFoodicsAccountsQuery.isSuccess, inactiveFoodicsAccountsQuery.data, searchTerm]);

  const Content = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center py-20">
          <PulseLoader color="lightgray" loading={true} size={15} speedMultiplier={0.6} />
        </div>
      );
    }

    if (selectedTab === UsersSettingsTabs.inactive) {
      if (!businessContext.business) {
        return null;
      }
      if (isAllBusiness(businessContext.business.id)) {
        return (
          <div className="flex justify-center items-center bg-white h-[80%] w-full px-[19%]">
            <div className="font-medium text-feintGray text-center text-sm">
              You cannot invite inactive users while in the{" "}
              <span className="text-sematicRed text-center">"All Businesses"</span> context. Please
              choose a single business from the profile tab in order to see the inactive users of
              that business.
            </div>
          </div>
        );
      }
      return inactiveFoodicsAccounts.length === 0 ? (
        <div className="flex justify-center items-center">
          <span>No results</span>
        </div>
      ) : (
        <SettingsUsersTable
          accounts={inactiveFoodicsAccounts}
          headers={["Email", "Branches", "Businesses", "Console Roles"]}
          columns={["email", "branchesCount", "businessesCount", "roles"]}
          selectedTab={selectedTab}
        />
      );
    }

    return users.length === 0 ? (
      <div className="flex justify-center items-center">
        <span>No results</span>
      </div>
    ) : (
      <SettingsUsersTable
        accounts={users}
        headers={["Email", "Branches", "Businesses", "Permissions"]}
        columns={["email", "branchesCount", "businessesCount", "is_root"]}
        selectedTab={selectedTab}
      />
    );
  };

  const [tabs] = React.useState([
    UsersSettingsTabs.active,
    UsersSettingsTabs.pending,
    UsersSettingsTabs.inactive,
  ]);

  return (
    <div className="h-full flex flex-col">
      <div className="flex space-x-4 items-center">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`px-6 py-2 rounded-b-none ${
              selectedTab === tab
                ? "border-b-2 border-dark text-dark font-medium"
                : "border-b-2 border-light text-light hover:text-dark hover:border-dark"
            }`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
        <div className="!ml-auto flex">
          <SearchBox term={searchTerm} onTermChanged={setSearchTerm} placeholder="Search email" />
        </div>
      </div>
      <div className="pt-8 flex-1 min-h-0">
        <Content />
      </div>
    </div>
  );
}
