import React from "react";
import { useLiveboards } from "./useLiveboards";
import { usePostHog } from "posthog-js/react";
import * as Sentry from "@sentry/react";

export function useCaptureLiveboardView() {
  const liveboards = useLiveboards();
  const posthog = usePostHog();

  return React.useCallback(
    (liveboardId: string) => {
      if (!liveboards.isSuccess) {
        return;
      }
      const liveboard = liveboards.data.find((l) => l.liveboard_id === liveboardId);
      if (liveboard) {
        posthog.capture("liveboardview", {
          liveboard: {
            id: liveboard.liveboard_id,
            name: liveboard.liveboard_name,
            is_favorite: liveboard.is_favorite,
            category: liveboard.category
              ? {
                name: liveboard.category.name,
              }
              : {
                name: "CUSTOM_LIVEBOARD",
              },
          },
        });
      } else {
        Sentry.captureEvent({
          message: "Liveboard not found",
          level: "error",
          extra: {
            liveboardId,
          },
        });
      }
    },
    [posthog, liveboards.isSuccess, liveboards.data],
  );
}
