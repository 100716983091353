import { EmailSchedule } from "../../../api";
import { BusinessAvatar } from "../../../components/QuickSettings/BusinessAvatar";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

export type SettingsEmailSchedulesRowProps = {
  emailSchedule: EmailSchedule;
  emailScheduleProperties: (keyof EmailSchedule)[];
};

export function SettingsEmailSchedulesRow({
  emailSchedule,
  emailScheduleProperties,
}: SettingsEmailSchedulesRowProps) {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/schedules/${emailSchedule.liveboard_id}`);
  };

  const renderEmailScheduleProperty = (property: keyof EmailSchedule) => {
    const value = emailSchedule[property];

    switch (property) {
      case "status":
        return <div className="first-letter:uppercase lowercase">{value}</div>;
      case "name":
        return (
          <div className="flex items-center gap-3">
            <BusinessAvatar
              businessDisplayName={value.toString()}
              className="bg-secondaryColor w-8 h-8"
            />
            <span>{value}</span>
          </div>
        );
      default:
        return value;
    }
  };

  return (
    <tr onClick={handleRowClick} className="group cursor-pointer hover:bg-lightGray">
      {emailScheduleProperties.map((property, index) => (
        <td
          key={`${emailSchedule.id}-${property}`}
          className={clsx("px-4 py-2", index === 0 && "group-hover:text-primaryColor pl-8")}
        >
          {renderEmailScheduleProperty(property)}
        </td>
      ))}
    </tr>
  );
}
