import { SettingsEmailSchedulesRow } from "./SettingsEmailSchedulesRow";
import { EmailSchedule } from "../../../api";
import clsx from "clsx";
import "./index.css";

export type SettingsEmailSchedulesTableProps = {
  emailSchedules: EmailSchedule[];
  headers: string[];
  emailSchedulesProperties: (keyof EmailSchedule)[];
  tableClassName?: string;
};

export function SettingsEmailSchedulesTable({
  emailSchedules,
  headers,
  emailSchedulesProperties,
  tableClassName = "settings-table settings-table-schedules",
}: SettingsEmailSchedulesTableProps) {
  return (
    <div className="flex gap-y-4 flex-col">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl font-semibold text-dark">Email Schedules</h1>
        <p className="text-dark">This is a list of all the email schedules</p>
      </div>
      <div className="overflow-x-auto">
        <table className={clsx("w-full table-fixed border-collapse", tableClassName)}>
          <thead>
            <tr className="bg-gray-50">
              {headers.map((header, index) => (
                <th key={index} className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {emailSchedules.map((emailSchedule) => (
              <SettingsEmailSchedulesRow
                key={emailSchedule.id}
                emailSchedule={emailSchedule}
                emailScheduleProperties={emailSchedulesProperties}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
