import React from "react";
import { Business } from "../../../api";
import { SvgIcon } from "../../../svg";
import { BusinessContextMenu } from "./BusinessContextMenu";

export type SettingsBusinessesRowProps = {
  business: Business;
  businessProperties: (keyof Business)[];
};

export function SettingsBusinessesRow({
  business,
  businessProperties,
}: SettingsBusinessesRowProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [showEditOptions, setShowEditOptions] = React.useState(false);

  const handleEditClick = () => {
    setShowEditOptions(!showEditOptions);
  };

  const renderBusinessProperty = (property: keyof Business) => {
    const value = (() => {
      switch (property) {
        case "display_name":
          return (
            <div className="flex items-center">
              <div className="table-element-avatar flex-shrink-0 w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-sm font-medium">
                {business.display_name[0].toUpperCase()}
              </div>
              <span className="content-label-subhead text-dark ml-2 truncate">
                {business.display_name}
              </span>
            </div>
          );
        case "name":
          return business.name;
        case "venues_count":
          return business.venues_count;
        case "users_count":
          return business.users_count;
        default:
          return business[property];
      }
    })();

    return (
      <div className="truncate" title={typeof value === "string" ? value : undefined}>
        {value}
      </div>
    );
  };

  return (
    <tr onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <td className="edit-icon-cell">
        <div className="relative">
          <div
            className={`cursor-pointer ${isHovered || showEditOptions ? "visible" : "invisible"} ${
              showEditOptions ? "text-dark" : "text-light hover:text-dark"
            }`}
            onClick={handleEditClick}
          >
            <div className="inline-block">
              <SvgIcon icon="Edit" />
            </div>
          </div>
          <BusinessContextMenu
            show={showEditOptions}
            setShow={setShowEditOptions}
            business={business}
          />
        </div>
      </td>
      {businessProperties.map((property) => (
        <td key={property} className="px-4 py-2 max-w-xs">
          {renderBusinessProperty(property)}
        </td>
      ))}
    </tr>
  );
}
