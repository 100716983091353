import React from "react";
import { SettingsBusinessesRow } from "./SettingsBusinessesRow";
import { SvgIcon } from "../../../svg";
import { Dialog } from "../../../components/Dialog";
import { Business, onboardBusinessInOrganization } from "../../../api";
import { useAccessToken } from "../../../Auth/useAccessToken";

export type SettingsBusinessesTableProps = {
  businesses: Business[];
  headers: string[];
  businessProperties: (keyof Business)[];
  tableClassName?: string;
};

export function SettingsBusinessesTable({
  businesses,
  headers,
  businessProperties,
  tableClassName = "settings-table",
}: SettingsBusinessesTableProps) {
  const [showAddBusinessDialog, setShowAddBusinessDialog] = React.useState(false);
  const { accessToken } = useAccessToken();

  const handleAddBusiness = () => onboardBusinessInOrganization(accessToken);

  const MessageContainer = React.useCallback((props: React.PropsWithChildren) => {
    return <div style={{ textAlign: "center" }}>{props.children}</div>;
  }, []);

  const message = (
    <MessageContainer>
      Make sure to <strong className="text-dark">Logout from the Console</strong> before you onboard
      a new Business.
      <br />
      Then enter the <strong className="text-dark">Owner credentials</strong> of the new Business.
    </MessageContainer>
  );

  return (
    <div className="flex gap-y-4 flex-col">
      <div>
        <button
          className="px-4 py-2 bg-backgroundGray hover:bg-light rounded-2xl w-[175px] h-[40px] flex justify-around items-center"
          onClick={() => setShowAddBusinessDialog(true)}
        >
          <SvgIcon icon="Plus" />
          <span>Add Business</span>
        </button>
      </div>
      <div>
        <table className={`${tableClassName} w-full table-fixed`}>
          <thead>
            <tr>
              {/* Empty header for edit column */}
              <th className="edit-icon-header w-10"></th>
              {headers.map((header, index) => (
                <th key={index} className="px-4 py-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {businesses.map((business) => (
              <SettingsBusinessesRow
                key={business.id}
                business={business}
                businessProperties={businessProperties}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Dialog
        show={showAddBusinessDialog}
        title="Add Business"
        message={message}
        onConfirm={handleAddBusiness}
        onCancel={() => setShowAddBusinessDialog(false)}
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </div>
  );
}
