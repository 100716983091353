import React from "react";
import { SvgIcon } from "../../../svg";
import { UsersSettingsTab } from "./tabs";
import { useUserContextMenuOptions } from "./useUserContextMenuOptions";
import { ContextMenu, ContextMenuOption } from "../../../components/ContextMenu";
import { useSelf } from "../../../hooks/useSelf";

export type UserRowModel = { id: string } & Partial<{
  email: string;
  name: string;
  roles: string[];
  branchesCount: number;
  businessesCount: number;
  is_root: boolean;
}>;

type SettingsUsersRowProps = {
  account: UserRowModel;
  columns: (keyof UserRowModel)[];
  selectedTab: UsersSettingsTab;
};

export function SettingsUsersRow({ account, columns, selectedTab }: SettingsUsersRowProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [showContextMenu, setShowContextMenu] = React.useState(false);

  const self = useSelf();
  const isCurrentUserDemo = self.data?.is_demo ?? false;

  const handleEditClick = () => {
    setShowContextMenu(!showContextMenu);
  };

  const renderAccountProperty = (property: keyof UserRowModel) => {
    const value = (() => {
      switch (property) {
        case "email":
          return (
            <div className="flex items-center">
              <div className="table-element-avatar flex-shrink-0 w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-sm font-medium">
                {account.email && account.email.length > 0 ? account.email[0].toUpperCase() : "-"}
              </div>
              <span className="content-label-subhead text-dark ml-2 truncate" title={account.email}>
                {account.email || "-"}
              </span>
            </div>
          );
        case "name":
          return account.name || "-";
        case "roles":
          return account.roles?.join(", ") || "-";
        case "branchesCount":
          return account.branchesCount || "-";
        case "businessesCount":
          return account.businessesCount || "-";
        case "is_root":
          return account.is_root ? "Admin" : "Normal";
        default:
          throw new Error(`SettingsUserRow: unknown property: ${property}`);
      }
    })();

    return (
      <div
        className="truncate text-sm font-normal"
        title={typeof value === "string" ? value : undefined}
      >
        {value}
      </div>
    );
  };

  const tmpUserContextOptions = useUserContextMenuOptions(selectedTab, account);
  const userContextOptions: ContextMenuOption[] = React.useMemo(() => {
    if (isCurrentUserDemo) {
      return tmpUserContextOptions.map((o) => ({
        ...o,
        handler: async () => o.demoHandler(),
      }));
    }
    return tmpUserContextOptions;
  }, [isCurrentUserDemo, tmpUserContextOptions]);

  return (
    <tr onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <td className="edit-icon-cell">
        <div className="relative">
          <div
            className={`cursor-pointer ${isHovered || showContextMenu ? "visible" : "invisible"} ${
              showContextMenu ? "text-dark" : "text-light hover:text-dark"
            }`}
            onClick={handleEditClick}
          >
            <SvgIcon icon="Edit" />
          </div>
          <ContextMenu
            show={showContextMenu}
            setShow={setShowContextMenu}
            options={userContextOptions}
          />
        </div>
      </td>
      {columns.map((property) => (
        <td key={property} className="px-4 py-2 max-w-xs">
          {renderAccountProperty(property)}
        </td>
      ))}
    </tr>
  );
}
