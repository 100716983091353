import { SettingsUsersRow, UserRowModel } from "./SettingsUsersRow";
import { UsersSettingsTab } from "./tabs";

export type SettingsUsersTableProps = {
  accounts: UserRowModel[];
  headers: string[];
  columns: (keyof UserRowModel)[];
  tableClassName?: string;
  selectedTab: UsersSettingsTab;
};

export function SettingsUsersTable({
  accounts,
  headers,
  columns,
  tableClassName = "settings-table",
  selectedTab,
}: SettingsUsersTableProps) {
  return (
    <div className="h-full overflow-auto">
      <table className={`${tableClassName} w-full table-fixed`}>
        <thead>
          <tr>
            <th className="edit-icon-header w-10"></th>
            {headers.map((header, index) => (
              <th key={index} className="px-4 py-2">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <SettingsUsersRow
              key={account.id}
              account={account}
              columns={columns}
              selectedTab={selectedTab}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
